import { Action, action, thunk, Thunk } from 'easy-peasy';
import axios from "axios";
import Notification from "../../web/utils/Notification";
import http from '../../web/utils/http';
import ErrorHandler from '../../web/utils/ErrorHandler';
import { get } from 'lodash';

export interface IFeedbackModel {
    /*****..........@...ANY TYPE...@.......*****/
    feedback: Array<Object>;

    /*****..........@...ACTION TYPE...@.......*****/
    setFeedback: Action<IFeedbackModel, any>;

    /*****..........@...THUNK TYPE...@.......*****/
    loadFeedback: Thunk<IFeedbackModel, any>;
    searchFeedback: Thunk<IFeedbackModel, any>;
    deleteFeedback: Thunk<IFeedbackModel, any>;

}

const FeedbackModel : IFeedbackModel = {
    feedback:[],

    /*****..........@...ACTION TYPE...@.......*****/
    setFeedback: action((state, payload)=>{
        state.feedback = payload;
    }),

     /*****..........@...THUNK TYPE...@.......*****/
     
     loadFeedback:thunk(async (actions,planId) => {
        // let data = "save";
        let params={
            'is_main':true,
            'status':'publish'
        }
        const response = await http().get('global-tech-feedback' , {params}).then(res=>{
            const { data } = res;
            actions.setFeedback(data);
        }).catch(error=>{
            console.log(error);
        });
    }),

    searchFeedback:thunk(async (actions,data) => {
        let params ={
            'page': get(data,'page') || 1,
            'pageSize': get(data,'pageSize') || 10,
            'title': get(data, 'title') || "",
            'status': get(data, 'status') || "",
        }
        const response = await http().get('global-tech-feedback/search',{params}).then(res=>{
            const { data } = res;
            actions.setFeedback(data);
        }).catch(error=>{
            console.log(error);
        });
    }),

    deleteFeedback:thunk(async (actions, id) => {
        const response = await http().delete(`global-tech-feedback/${id}`).then(res=>{
            actions.loadFeedback(actions);
            Notification.success("Feedback deleted Successfully.");
        }).catch(error=>{
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        });
    }),
}

export default FeedbackModel;