import { Action, action, thunk, Thunk } from 'easy-peasy';
import axios from "axios";
import Notification from "../../web/utils/Notification";
import http from '../../web/utils/http';
import ErrorHandler from '../../web/utils/ErrorHandler';
import { get } from 'lodash';
export interface IPdfModel {
    /*****..........@...ANY TYPE...@.......*****/
    pdfs: Array<Object>;
    pdfFromData:any;

    /*****..........@...ACTION TYPE...@.......*****/
    setPdf: Action<IPdfModel, any>;
    setPdfFromData: Action<IPdfModel, any>;

    /*****..........@...THUNK TYPE...@.......*****/
    savePdf:  Thunk<IPdfModel, any>;

}

const PdfModel : IPdfModel = {
    pdfs:[],
    pdfFromData:{},

    /*****..........@...ACTION TYPE...@.......*****/
    setPdf: action((state, payload)=>{
        state.pdfs = payload;
    }),

    setPdfFromData: action((state,payload)=>{
        state.pdfFromData = payload;
    }),

     /*****..........@...THUNK TYPE...@.......*****/

    savePdf:thunk(async (actions, data) => {
        const response = await http({ "Content-Type": "multipart/form-data" }).post('/admin-panel/pdf-upload',data).then(res=>{
            const { data } = res;
            Notification.success("Changes Saved Successfully.");
            // actions.searchAwards(actions);
        }).catch(error=>{
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        });
    }),


}

export default PdfModel;