import { Action, action, thunk, Thunk } from 'easy-peasy';
import axios from "axios";
import Notification from "../../web/utils/Notification";
import http from '../../web/utils/http';
import ErrorHandler from '../../web/utils/ErrorHandler';
import { get } from 'lodash';
export interface IAwardModel {
    /*****..........@...ANY TYPE...@.......*****/
    awards: Array<Object>;
    awardFromData:any;

    /*****..........@...ACTION TYPE...@.......*****/
    setAward: Action<IAwardModel, any>;
    setAwardFromData: Action<IAwardModel, any>;

    /*****..........@...THUNK TYPE...@.......*****/
    searchAwards: Thunk<IAwardModel, any>;
    loadAwards: Thunk<IAwardModel, any>;
    saveAward:  Thunk<IAwardModel, any>;
    updateAward:  Thunk<IAwardModel, any>;
    deleteAward:  Thunk<IAwardModel, any>;
}

const AwardModel : IAwardModel = {
    awards:[],
    awardFromData:{},

    /*****..........@...ACTION TYPE...@.......*****/
    setAward: action((state, payload)=>{
        state.awards = payload;
    }),

    setAwardFromData: action((state,payload)=>{
        state.awardFromData = payload;
    }),

     /*****..........@...THUNK TYPE...@.......*****/
     searchAwards:thunk(async (actions,data) => {
        let params ={
            'page': get(data,'page') || 1,
            'pageSize': get(data,'pageSize') || 10,
            'title': get(data, 'title') || "",
            'status': get(data, 'status') || "",
        }
        const response = await http().get('award/search',{params}).then(res=>{
            const { data } = res;
            actions.setAward(data);
        }).catch(error=>{
            console.log(error);
        });
    }),
     
     loadAwards:thunk(async (actions,data) => {
        let params ={
            'page': get(data,'page') || 1,
            'pageSize': get(data,'pageSize') || 10
        }
        const response = await http().get('award',{params}).then(res=>{
            const { data } = res;
            actions.setAward(data);
        }).catch(error=>{
            console.log(error);
        });
    }),

    saveAward:thunk(async (actions, data) => {
        const response = await http({ "Content-Type": "multipart/form-data" }).post('award',data).then(res=>{
            const { data } = res;
            Notification.success("Changes Saved Successfully.");
            actions.searchAwards(actions);
        }).catch(error=>{
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        });
    }),

    updateAward:thunk(async (actions, data) => {
        // let data = "save";
        const response = await http({ "Content-Type": "multipart/form-data" }).put('award',data).then(res=>{
            const { data } = res;
            Notification.success("Changes Updated Successfully.");
            // actions.searchAwards(actions);
        }).catch(error=>{
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        });
    }),

    deleteAward:thunk(async (actions, id) => {
        const response = await http().delete(`award/${id}`).then(res=>{
            actions.searchAwards(actions);
            Notification.success("Award deleted Successfully.");
        }).catch(error=>{
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        });
    }),


}

export default AwardModel;