import { Action, action, thunk, Thunk } from 'easy-peasy';
import axios from "axios";
import Notification from "../../web/utils/Notification";
import http from '../../web/utils/http';
import ErrorHandler from '../../web/utils/ErrorHandler';
import { get } from 'lodash';
export interface IGlobalModel {
    /*****..........@...ANY TYPE...@.......*****/
    global: Array<Object>;
    globalFromData:any;

    /*****..........@...ACTION TYPE...@.......*****/
    setGlobal: Action<IGlobalModel, any>;
    setGlobalFromData: Action<IGlobalModel, any>;

    /*****..........@...THUNK TYPE...@.......*****/
    searchGlobal: Thunk<IGlobalModel, any>;
    loadGlobal: Thunk<IGlobalModel, any>;
    saveGlobal:  Thunk<IGlobalModel, any>;
    updateGlobal:  Thunk<IGlobalModel, any>;
    deleteGlobal:  Thunk<IGlobalModel, any>;
}

const GlobalTechSeriesModel : IGlobalModel = {
    global:[],
    globalFromData:{},

    /*****..........@...ACTION TYPE...@.......*****/
    setGlobal: action((state, payload)=>{
        state.global = payload;
    }),

    setGlobalFromData: action((state,payload)=>{
        state.globalFromData = payload;
    }),

     /*****..........@...THUNK TYPE...@.......*****/
     searchGlobal:thunk(async (actions,data) => {
        let params ={
            'page': get(data,'page') || 1,
            'pageSize': get(data,'pageSize') || 10,
            'title': get(data, 'title') || "",
            'status': get(data, 'status') || "",
        }
        const response = await http().get('global-tech-series/search',{params}).then(res=>{
            const { data } = res;
            actions.setGlobal(data);
        }).catch(error=>{
            console.log(error);
        });
    }),
     
     loadGlobal:thunk(async (actions,data) => {
        let params ={
            'page': get(data,'page') || 1,
            'pageSize': get(data,'pageSize') || 10
        }
        const response = await http().get('global-tech-series',{params}).then(res=>{
            const { data } = res;
            actions.setGlobal(data);
        }).catch(error=>{
            console.log(error);
        });
    }),

    saveGlobal:thunk(async (actions, data) => {
        const response = await http().post('global-tech-series',data).then(res=>{
            const { data } = res;
            Notification.success("Changes Saved Successfully.");
            actions.searchGlobal(actions);
        }).catch(error=>{
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        });
    }),

    updateGlobal:thunk(async (actions, data) => {
        // let data = "save";
        const response = await http().put('global-tech-series',data).then(res=>{
            const { data } = res;
            Notification.success("Changes Updated Successfully.");
            // actions.searchAwards(actions);
        }).catch(error=>{
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        });
    }),

    deleteGlobal:thunk(async (actions, id) => {
        const response = await http().delete(`global-tech-series/${id}`).then(res=>{
            actions.searchGlobal(actions);
            Notification.success("Global Tech Series deleted Successfully.");
        }).catch(error=>{
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        });
    }),


}

export default GlobalTechSeriesModel;