import React from 'react';
import ReactDOM from 'react-dom/client';
import { StoreProvider } from 'easy-peasy';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import reportWebVitals from './reportWebVitals';
import store from './store';
import App from './App';
// import "antd/dist/antd.css";
import './index.css';
import './web/common-styles/Common.scss';
import 'antd/dist/antd.min.css';
import 'react-quill/dist/quill.snow.css';

const StoreProviderOverride = StoreProvider as any;
const darkTheme = createTheme({
  palette: {
    mode: 'light',
  },
});
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <StoreProviderOverride store={store}>
      <MuiThemeProvider theme={darkTheme}>
        <App />
      </MuiThemeProvider>
    </StoreProviderOverride>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
