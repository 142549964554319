import { action, Action, thunk, Thunk } from "easy-peasy";
import { get } from "lodash";
import ErrorHandler from "../../web/utils/ErrorHandler";
import http from "../../web/utils/http";
import Notification from "../../web/utils/Notification";

export interface INewsPressReleaseModel {
  /*****..........@...ANY TYPE...@.......*****/
  NewsPressRelease: Array<Object>;
  NewsPressReleaseFormData: any;

  /*****..........@...ACTION TYPE...@.......*****/
  setNewsPressReleaseModel: Action<INewsPressReleaseModel, any>;
  setNewsPressFromData: Action<INewsPressReleaseModel, any>;

  /*****..........@...THUNK TYPE...@.......*****/
  searchNewsPressRelease: Thunk<INewsPressReleaseModel, any>;
  loadNewsPressRelease: Thunk<INewsPressReleaseModel, any>;
  saveNewsPressRelease: Thunk<INewsPressReleaseModel, any>;
  updateNewsPressRelease: Thunk<INewsPressReleaseModel, any>;
  deleteNewsPressRelease: Thunk<INewsPressReleaseModel, any>;
}

const NewsPressReleaseModel: INewsPressReleaseModel = {
  NewsPressRelease: [],
  NewsPressReleaseFormData: {},

  /*****..........@...ACTION TYPE...@.......*****/
  setNewsPressReleaseModel: action((state, payload) => {
    state.NewsPressRelease = payload;
  }),

  setNewsPressFromData: action((state, payload) => {
    state.NewsPressReleaseFormData = payload;
  }),

  /*****..........@...THUNK TYPE...@.......*****/
  searchNewsPressRelease: thunk(async (actions, data) => {
    let params = {
      'page': get(data,'page') || 1,
      'pageSize': get(data,'pageSize') || 10,
      'title': get(data, 'title') || "",
      'status': get(data, 'status') || "",
    };
    const response = await http()
      .get("NewsAndPressRelease/search", {params})
      .then((res) => {
        const { data } = res;
        actions.setNewsPressReleaseModel(data);
        console.log("NewsPressReleaseModel",data);
      })
      .catch((error) => {
        ErrorHandler(error);
        return new Promise((resolve, reject) => reject("error"));
      });
  }),

  loadNewsPressRelease: thunk(async (actions, data) => {
    let params = {
      'page': get(data,'page') || 1,
      'pageSize': get(data,'pageSize') || 10,
    };
    const response = await http()
      .get("NewsAndPressRelease", {params})
      .then((res) => {
        const { data } = res;
        actions.setNewsPressReleaseModel(data);
        console.log("NewsPressReleaseModel",data);
      })
      .catch((error) => {
        ErrorHandler(error);
        return new Promise((resolve, reject) => reject("error"));
      });
  }),

  saveNewsPressRelease: thunk(async (actions, data) => {
    const response = await http({ "Content-Type": "multipart/form-data" })
      .post("NewsAndPressRelease", data)
      .then((res) => {
        const { data } = res;
        actions.searchNewsPressRelease(actions);
        Notification.success("Changes save successfully");
      })
      .catch((error) => {
        ErrorHandler(error);
        return new Promise((resolve, reject) => reject("error"));
      });
  }),

  updateNewsPressRelease: thunk(async (actions, data) => {
    const response = await http({ "Content-Type": "multipart/form-data" })
      .put("NewsAndPressRelease", data)
      .then((res) => {
        const { data } = res;
        // actions.searchNewsPressRelease(actions);
        Notification.success("Changes updated successfully");
      })
      .catch((error) => {
        ErrorHandler(error);
        return new Promise((resolve, reject) => reject("error"));
      });
  }),

  deleteNewsPressRelease: thunk(async (actions, id) => {
    const response = await http()
      .delete(`NewsAndPressRelease/${id}`)
      .then((res) => {
        const { data } = res;
        actions.searchNewsPressRelease(actions);
        Notification.success("deleted successfully");
      })
      .catch((error) => {
        ErrorHandler(error);
        return new Promise((resolve, reject) => reject("error"));
      });
  }),
};

export default NewsPressReleaseModel;
