import { Action, action, thunk, Thunk } from 'easy-peasy';
import axios from "axios";
import {get} from 'lodash';
import Notification from "../../web/utils/Notification";
import http from '../../web/utils/http';
import ErrorHandler from '../../web/utils/ErrorHandler';
export interface IJobModel {
    /*****..........@...ANY TYPE...@.......*****/
    jobs: Array<Object>;
    jobFromData: any;

    /*****..........@...ACTION TYPE...@.......*****/
    setJobs: Action<IJobModel, any>;
    setJobFromData: Action<IJobModel, any>;

    /*****..........@...THUNK TYPE...@.......*****/
    searchJobs: Thunk<IJobModel, any>;
    loadJobs: Thunk<IJobModel, any>;
    saveJob: Thunk<IJobModel, any>;
    updateJob: Thunk<IJobModel, any>;
    deleteJob: Thunk<IJobModel, any>;
}

const JobModel: IJobModel = {
    jobs: [],
    jobFromData: {},

    /*****..........@...ACTION TYPE...@.......*****/
    setJobs: action((state, payload) => {
        state.jobs = payload;
    }),

    setJobFromData: action((state, payload) => {
        state.jobFromData = payload;
    }),

    /*****..........@...THUNK TYPE...@.......*****/
    searchJobs: thunk(async (actions, data) => {
        let params ={
            'page': get(data,'page') || 1,
            'pageSize': get(data,'pageSize') || 10,
            'title': get(data,'title') || "",
            'status': get(data,'status') || ""
        }
        const response = await http().get('job/search', { params }).then(res => {
            const { data } = res;
            actions.setJobs(data);
        }).catch(error => {
            console.log(error);
            Notification.error("Unable to fetch data");
        });
    }),

    loadJobs: thunk(async (actions, data) => {
        const response = await http().get('job').then(res => {
            const { data } = res;
            actions.setJobs(data);
        }).catch(error => {
            console.log(error);
            Notification.error("Unable to fetch data");
        });
    }),

    saveJob: thunk(async (actions, data) => {
        const response = await http().post('job', data).then(res => {
            const { data } = res;
            Notification.success("Changes Saved Successfully.");
            actions.searchJobs(actions);
        }).catch(error => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        });
    }),

    updateJob: thunk(async (actions, data) => {
        // let data = "save";
        const response = await http().put('job', data).then(res => {
            const { data } = res;
            Notification.success("Changes Updated Successfully.");
            // actions.searchJobs(actions);
        }).catch(error => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        });
    }),

    deleteJob: thunk(async (actions, id) => {
        const response = await http().delete(`job/${id}`).then(res => {
            actions.searchJobs(actions);
            Notification.success("Job deleted Successfully.");
        }).catch(error => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        });
    }),


}

export default JobModel;