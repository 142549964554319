import { Action, action, thunk, Thunk } from 'easy-peasy';
import axios from "axios";
import Notification from "../../web/utils/Notification";
import http from '../../web/utils/http';
import ErrorHandler from '../../web/utils/ErrorHandler';
import { get } from 'lodash';
export interface IClientModel {
    /*****..........@...ANY TYPE...@.......*****/
    clients: Array<Object>;
    clientFromData:any;

    /*****..........@...ACTION TYPE...@.......*****/
    setClients: Action<IClientModel, any>;
    setClientFromData: Action<IClientModel, any>;

    /*****..........@...THUNK TYPE...@.......*****/
    searchClients: Thunk<IClientModel, any>;
    loadClients: Thunk<IClientModel, any>;
    saveClient:  Thunk<IClientModel, any>;
    updateClient:  Thunk<IClientModel, any>;
    deleteClient:  Thunk<IClientModel, any>;
}

const ClientModel : IClientModel = {
    clients:[],
    clientFromData:{},

    /*****..........@...ACTION TYPE...@.......*****/
    setClients: action((state, payload)=>{
        state.clients = payload;
    }),

    setClientFromData: action((state,payload)=>{
        state.clientFromData=payload;
    }),

     /*****..........@...THUNK TYPE...@.......*****/
     searchClients:thunk(async (actions,data) => {
        let params = {
            'page': get(data,'page',1),
            'pageSize': get(data,'pageSize',10),
            'title': get(data, 'title') || "",
            'status': get(data, 'status') || "",
        }
        const response = await http().get('client/search',{params}).then(res=>{
            const { data } = res;
            actions.setClients(data);
        }).catch(error=>{
            console.log(error);
        });
    }),
     
     loadClients:thunk(async (actions,data) => {
        let params = {
            'page': get(data,'page',1),
            'pageSize': get(data,'pageSize',10)
        }
        const response = await http().get('client',{params}).then(res=>{
            const { data } = res;
            actions.setClients(data);
        }).catch(error=>{
            console.log(error);
        });
    }),

    saveClient:thunk(async (actions, data) => {
        let obj ={
            "name":data.name,
            "logo":data.logo,
            "colour_logo":data.colour_logo,
            "status":data.status
        }
        // let data = "save";
        const response = await http({ "Content-Type": "multipart/form-data" }).post('client',obj).then(res=>{
            const { data } = res;
            Notification.success("Changes Saved Successfully.");
            actions.searchClients(actions);
        }).catch(error=>{
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        });
    }),

    updateClient:thunk(async (actions, data) => {
        const response = await http({ "Content-Type": "multipart/form-data" }).put('client',data).then(res=>{
            const { data } = res;
            Notification.success("Changes Updated Successfully.");
            // actions.searchClients(actions);
        }).catch(error=>{
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        });
    }),

    deleteClient:thunk(async (actions, id) => {
        const response = await http().delete(`client/${id}`).then(res=>{
            // actions.loadClients(actions);
            Notification.success("Client deleted Successfully.");
            actions.searchClients(actions);
        }).catch(error=>{
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        });
    }),


}

export default ClientModel;